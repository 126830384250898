<template>
  <div class="cont">
    <div class="left" @click="last.name?toArticle(last.id):''">
      <p>上一篇:{{last.name}}</p>
      <img :src="last.name?leftSrcO:leftSrc">
    </div>
    <div class="right" @click="next.name?toArticle(next.id):''">
      <img :src="next.name?rightSrcO:rightSrc">
      <p>下一篇:{{next.name}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "lastAndNext",
  props:{
    last:{
    },
    next:{
    }
  },
  data() {
    return{
      leftSrc:require('./../../assets/image/icon/left.png'),
      leftSrcO:require('./../../assets/image/icon/left (1).png'),
      rightSrc:require('./../../assets/image/icon/right.png'),
      rightSrcO:require('./../../assets/image/icon/right (1).png')
    }
  },methods:{
    toArticle(id){
      if(id!=null){
        this.$emit('toArticle',id)
      }
    }
  }
}
</script>

<style scoped>
.cont {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0;

}
img{
  width: 20px;
  height: 20px;
  margin: 10px;
}

.left, .right {
  width: 45%;
  height: 40px;
  display: flex;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  cursor: pointer;
  cursor: hand;
}

.left p, .right p {
  margin: 5px 0;
  line-height: 30px;
  height: 30px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.left{
  margin-right: 5%;
}
.right{
  margin-left: 5%;
}

.left p {
  width: 100%;
  text-align: right;
  justify-content: flex-end;

}
</style>